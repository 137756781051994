// tenant.service.ts
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  public clientEnvironment: any;
  environmentType = environment.type || 'dev';

  public setClientEnvironment(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const client = this.extractClientFromUrl(window.location.hostname);
      const isSubdomain = client !== '';

      const environment = isSubdomain
        ? require(`src/environments/${client}/environment.${this.environmentType}`).environment
        : require(`src/environments/environment.${this.environmentType}`).environment;

      this.clientEnvironment = {
        client: client,
        environment: environment,
      };
      console.log(this.clientEnvironment);
      resolve();
    });
  }

  private extractClientFromUrl(url: string): string {
    const parts = url.split('.');
    return parts.length >= 2 ? parts[0] : '';
  }
}
